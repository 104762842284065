define("discourse/plugins/discourse-adplugin/discourse/components/google-adsense", ["exports", "@ember/component", "@ember/runloop", "@ember/template", "@ember-decorators/component", "rsvp", "discourse/lib/decorators", "discourse/lib/environment", "discourse/lib/load-script", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _runloop, _template, _component2, _rsvp, _decorators, _environment, _loadScript, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    <div class="google-adsense-label"><h2>{{i18n
          "adplugin.advertisement_label"
        }}</h2></div>
    <div
      class="google-adsense-content"
      id={{if this.isResponsive "google-adsense__responsive"}}
      style={{this.adWrapperStyle}}
    >
      <ins
        class="adsbygoogle"
        style={{this.adInsStyle}}
        data-ad-client="ca-pub-{{this.publisher_id}}"
        data-ad-slot={{this.ad_code}}
        data-ad-format={{this.autoAdFormat}}
      >
      </ins>
    </div>
  {{/if}}
  */
  {
    "id": "D7TvJb1u",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[10,0],[14,0,\"google-adsense-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,3],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n  \"],[10,0],[14,0,\"google-adsense-content\"],[15,1,[52,[30,0,[\"isResponsive\"]],\"google-adsense__responsive\"]],[15,5,[30,0,[\"adWrapperStyle\"]]],[12],[1,\"\\n    \"],[10,\"ins\"],[14,0,\"adsbygoogle\"],[15,5,[30,0,[\"adInsStyle\"]]],[15,\"data-ad-client\",[29,[\"ca-pub-\",[30,0,[\"publisher_id\"]]]]],[15,\"data-ad-slot\",[30,0,[\"ad_code\"]]],[15,\"data-ad-format\",[30,0,[\"autoAdFormat\"]]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"h2\",\"i18n\",\"ins\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/google-adsense.hbs",
    "isStrictMode": false
  });
  let _loaded = false,
    _promise = null,
    renderCounts = {};
  function parseAdWidth(value) {
    if (value === "responsive") {
      return "auto";
    }
    if (value.startsWith("fluid")) {
      return "fluid";
    }
    const w = parseInt(value.substring(0, 3).trim(), 10);
    if (isNaN(w)) {
      return "auto";
    } else {
      return `${w}px`;
    }
  }
  function parseAdHeight(value) {
    if (value === "responsive") {
      return "auto";
    }
    if (value.startsWith("fluid")) {
      return "fluid";
    }
    const h = parseInt(value.substring(4, 7).trim(), 10);
    if (isNaN(h)) {
      return "auto";
    } else {
      return `${h}px`;
    }
  }
  function loadAdsense() {
    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }
    const adsenseSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    _promise = (0, _loadScript.default)(adsenseSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }
  const DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "adsense_topic_list_top_code",
      sizes: "adsense_topic_list_top_ad_sizes"
    },
    "topic-above-post-stream": {
      code: "adsense_topic_above_post_stream_code",
      sizes: "adsense_topic_above_post_stream_ad_sizes"
    },
    "topic-above-suggested": {
      code: "adsense_topic_above_suggested_code",
      sizes: "adsense_topic_above_suggested_ad_sizes"
    },
    "post-bottom": {
      code: "adsense_post_bottom_code",
      sizes: "adsense_post_bottom_ad_sizes"
    }
  };
  const MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "adsense_mobile_topic_list_top_code",
      sizes: "adsense_mobile_topic_list_top_ad_size"
    },
    "topic-above-post-stream": {
      code: "adsense_mobile_topic_above_post_stream_code",
      sizes: "adsense_mobile_topic_above_post_stream_ad_size"
    },
    "topic-above-suggested": {
      code: "adsense_mobile_topic_above_suggested_code",
      sizes: "adsense_mobile_topic_above_suggested_ad_size"
    },
    "post-bottom": {
      code: "adsense_mobile_post_bottom_code",
      sizes: "adsense_mobile_post_bottom_ad_size"
    }
  };
  const GoogleAdsense = dt7948.c(class GoogleAdsense extends _adComponent.default {
    loadedGoogletag = false;
    publisher_id = null;
    ad_width = null;
    ad_height = null;
    adRequested = false;
    init() {
      let config, size;
      const placement = this.get("placement");
      if (this.site.mobileView) {
        config = MOBILE_SETTINGS[placement];
      } else {
        config = DESKTOP_SETTINGS[placement];
      }
      if (!renderCounts[placement]) {
        renderCounts[placement] = 0;
      }
      const sizes = (this.siteSettings[config.sizes] || "").split("|");
      if (sizes.length === 1) {
        size = sizes[0];
      } else {
        size = sizes[renderCounts[placement] % sizes.length];
        renderCounts[placement] += 1;
      }
      this.set("ad_width", parseAdWidth(size));
      this.set("ad_height", parseAdHeight(size));
      this.set("ad_code", this.siteSettings[config.code]);
      this.set("publisher_id", this.siteSettings.adsense_publisher_code);
      super.init();
    }
    async _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      this.set("adRequested", true);
      await loadAdsense();
      if (this.isDestroyed || this.isDestroying) {
        // Component removed from DOM before script loaded
        return;
      }
      try {
        const adsbygoogle = window.adsbygoogle ||= [];
        adsbygoogle.push({}); // ask AdSense to fill one ad unit
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error("Adsense error:", ex);
      }
    }
    didInsertElement() {
      super.didInsertElement();
      if (!this.get("showAd")) {
        return;
      }
      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    }
    isResponsive(adWidth) {
      return ["auto", "fluid"].includes(adWidth);
    }
    static #_ = (() => dt7948.n(this.prototype, "isResponsive", [(0, _decorators.default)("ad_width")]))();
    isFluid(adWidth) {
      return adWidth === "fluid";
    }
    static #_2 = (() => dt7948.n(this.prototype, "isFluid", [(0, _decorators.default)("ad_width")]))();
    classForSlot(placement, showAd) {
      return showAd ? (0, _template.htmlSafe)(`adsense-${placement}`) : "";
    }
    static #_3 = (() => dt7948.n(this.prototype, "classForSlot", [(0, _decorators.default)("placement", "showAd")]))();
    autoAdFormat(isResponsive, isFluid) {
      return isResponsive ? (0, _template.htmlSafe)(isFluid ? "fluid" : "auto") : false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "autoAdFormat", [(0, _decorators.default)("isResponsive", "isFluid")]))();
    adWrapperStyle(w, h, isResponsive) {
      return (0, _template.htmlSafe)(isResponsive ? "" : `width: ${w}; height: ${h};`);
    }
    static #_5 = (() => dt7948.n(this.prototype, "adWrapperStyle", [(0, _decorators.default)("ad_width", "ad_height", "isResponsive")]))();
    adInsStyle(adWrapperStyle, isResponsive) {
      return (0, _template.htmlSafe)(`display: ${isResponsive ? "block" : "inline-block"}; ${adWrapperStyle}`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "adInsStyle", [(0, _decorators.default)("adWrapperStyle", "isResponsive")]))();
    showAdsenseAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_adsense_ads;
    }
    static #_7 = (() => dt7948.n(this.prototype, "showAdsenseAds", [_decorators.default]))();
    showAd(publisherId, showAdsenseAds, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showAdsenseAds && showToGroups && showAfterPost && showOnCurrentPage;
    }
    static #_8 = (() => dt7948.n(this.prototype, "showAd", [(0, _decorators.default)("publisher_id", "showAdsenseAds", "showToGroups", "showAfterPost", "showOnCurrentPage")]))();
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.adsense_nth_post_code, 10));
    }
    static #_9 = (() => dt7948.n(this.prototype, "showAfterPost", [(0, _decorators.default)("postNumber")]))();
  }, [(0, _component2.classNameBindings)(":google-adsense", "classForSlot", "isResponsive:adsense-responsive")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GoogleAdsense);
});