define("discourse/plugins/discourse-adplugin/discourse/components/adbutler-ad", ["exports", "@ember/component", "@ember/runloop", "rsvp", "discourse/lib/decorators", "discourse/lib/environment", "discourse/lib/load-script", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _runloop, _rsvp, _decorators, _environment, _loadScript, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    <div id={{this.divId}} class={{this.className}}></div>
  {{/if}}
  */
  {
    "id": "jgCU/K3F",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[10,0],[15,1,[30,0,[\"divId\"]]],[15,0,[30,0,[\"className\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/adbutler-ad.hbs",
    "isStrictMode": false
  });
  let _loaded = false,
    _promise = null,
    _c = 0;
  function loadAdbutler(adserverHostname) {
    if (_loaded) {
      return _rsvp.default.resolve();
    }
    if (_promise) {
      return _promise;
    }
    _promise = (0, _loadScript.default)("https://" + adserverHostname + "/app.js", {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }
  class AdbutlerAd extends _adComponent.default {
    divs = null;
    publisherId = null;
    init() {
      let dimensions = [728, 90];
      let configKey = "adbutler_";
      let className = "adbutler-";
      let dimClassName = "adbutler-ad";
      this.set("divs", []);
      if (this.site.mobileView) {
        dimensions = [320, 50];
        configKey += "mobile_";
        className += "mobile-";
        dimClassName = "adbutler-mobile-ad";
      }
      configKey += this.get("placement").replace(/-/g, "_") + "_zone_id";
      this.set("configKey", configKey);
      className += this.get("placement");
      this.set("className", className + " " + dimClassName);
      let zoneId = this.siteSettings[configKey];
      this.set("zoneId", zoneId);
      let divId = "placement-" + zoneId + "-" + _c;
      this.set("divId", divId);
      let publisherId = this.siteSettings.adbutler_publisher_id;
      this.set("publisherId", publisherId);
      _c++;
      this.divs.push({
        divId,
        publisherId,
        zoneId,
        dimensions
      });
      super.init();
    }
    _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }
      const adserverHostname = this.siteSettings.adbutler_adserver_hostname;
      loadAdbutler(adserverHostname).then(function () {
        if (this.divs.length > 0) {
          let abkw = window.abkw || "";
          window.AdButler.ads.push({
            handler: function (opt) {
              window.AdButler.register(opt.place.publisherId, opt.place.zoneId, opt.place.dimensions, opt.place.divId, opt);
            },
            opt: {
              place: this.divs.pop(),
              keywords: abkw,
              domain: adserverHostname,
              click: "CLICK_MACRO_PLACEHOLDER"
            }
          });
        }
      }.bind(this));
    }
    didInsertElement() {
      super.didInsertElement();
      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    }
    showAdbutlerAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_adbutler_ads;
    }
    static #_ = (() => dt7948.n(this.prototype, "showAdbutlerAds", [_decorators.default]))();
    showAd(publisherId, showAdbutlerAds, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showAdbutlerAds && showToGroups && showAfterPost && showOnCurrentPage;
    }
    static #_2 = (() => dt7948.n(this.prototype, "showAd", [(0, _decorators.default)("publisherId", "showAdbutlerAds", "showToGroups", "showAfterPost", "showOnCurrentPage")]))();
    showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }
      return this.isNthPost(parseInt(this.siteSettings.adbutler_nth_post, 10));
    }
    static #_3 = (() => dt7948.n(this.prototype, "showAfterPost", [(0, _decorators.default)("postNumber")]))();
  }
  _exports.default = AdbutlerAd;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdbutlerAd);
});