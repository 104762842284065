define("discourse/plugins/discourse-adplugin/discourse/components/ad-component", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse/lib/decorators", "discourse/plugins/discourse-adplugin/discourse/helpers/slot-position"], function (_exports, _component, _computed, _service, _decorators, _slotPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentCategoryId", [(0, _computed.or)("router.currentRoute.attributes.category.id", "router.currentRoute.parent.attributes.category_id")]))();
    #currentCategoryId = (() => (dt7948.i(this, "currentCategoryId"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentCategorySlug", [(0, _computed.or)("router.currentRoute.attributes.category.slug", "router.currentRoute.parent.attributes.category.slug")]))();
    #currentCategorySlug = (() => (dt7948.i(this, "currentCategorySlug"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "topicTagsDisableAds", [(0, _computed.alias)("router.currentRoute.parent.attributes.tags_disable_ads")]))();
    #topicTagsDisableAds = (() => (dt7948.i(this, "topicTagsDisableAds"), void 0))(); // Server needs to compute this in case hidden tags are being used.
    static #_5 = (() => dt7948.g(this.prototype, "isRestrictedCategory", [(0, _computed.or)("router.currentRoute.attributes.category.read_restricted", "router.currentRoute.parent.attributes.category.read_restricted")]))();
    #isRestrictedCategory = (() => (dt7948.i(this, "isRestrictedCategory"), void 0))();
    topicListTag(type, tag) {
      if (type === "tag" && tag) {
        return tag;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "topicListTag", [(0, _decorators.default)("router.currentRoute.attributes.__type", "router.currentRoute.attributes.id")]))();
    isPersonalMessage(topicType) {
      return topicType === "private_message";
    }
    static #_7 = (() => dt7948.n(this.prototype, "isPersonalMessage", [(0, _decorators.default)("router.currentRoute.parent.attributes.archetype")]))();
    showToGroups() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_to_groups;
    }
    static #_8 = (() => dt7948.n(this.prototype, "showToGroups", [_decorators.default]))();
    showOnCurrentPage(categoryId, topicTagsDisableAds, topicListTag, isPersonalMessage, isRestrictedCategory) {
      return !topicTagsDisableAds && (!categoryId || !this.siteSettings.no_ads_for_categories || !this.siteSettings.no_ads_for_categories.split("|").includes(categoryId.toString())) && (!topicListTag || !this.siteSettings.no_ads_for_tags || !this.siteSettings.no_ads_for_tags.split("|").includes(topicListTag)) && (!isPersonalMessage || !this.siteSettings.no_ads_for_personal_messages) && (!isRestrictedCategory || !this.siteSettings.no_ads_for_restricted_categories);
    }
    static #_9 = (() => dt7948.n(this.prototype, "showOnCurrentPage", [(0, _decorators.default)("currentCategoryId", "topicTagsDisableAds", "topicListTag", "isPersonalMessage", "isRestrictedCategory")]))();
    isNthPost(n) {
      return (0, _slotPosition.isNthPost)(n, this.get("postNumber"));
    }
    isNthTopicListItem(n) {
      return (0, _slotPosition.isNthTopicListItem)(n, this.get("indexNumber"));
    }
  }
  _exports.default = AdComponent;
});