define("discourse/plugins/discourse-adplugin/discourse/templates/connectors/after-topic-list-item/discourse-adplugin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{ad-slot
    placement="topic-list-between"
    category=this.category.slug
    indexNumber=this.index
    childTagName="td"
  }}
  */
  {
    "id": "zqOFJc6y",
    "block": "[[[1,[28,[35,0],null,[[\"placement\",\"category\",\"indexNumber\",\"childTagName\"],[\"topic-list-between\",[30,0,[\"category\",\"slug\"]],[30,0,[\"index\"]],\"td\"]]]]],[],false,[\"ad-slot\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/connectors/after-topic-list-item/discourse-adplugin.hbs",
    "isStrictMode": false
  });
});